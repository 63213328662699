import React, { useState } from 'react';
import { Sync as SyncIcon, Upload as UploadIcon } from '@mui/icons-material';
import { Button, Typography, TextField, Box } from '@mui/material';
import axiosRequest from '../axiosRequest';

// Define regular expressions for each category to handle variations
const categoryKeywords = {
    'air curtain': /air[_\s]?curtains?/i,
    'fhc': /fhc/i,
    'security doors': /security[_\s]?doors?/i,
    'rain water leaks': /rain[_\s]?water[_\s]?leaks?/i
};

const EgnyteSync = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [apiKey, setApiKey] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Handle file selection
    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    // Handle API key change
    const handleApiKeyChange = (event) => {
        setApiKey(event.target.value);
    };

    // Validate and categorize files
    const validateFiles = (files) => {
        const matchedCategories = {};

        // Loop through selected files
        for (let i = 0; i < files.length; i++) {
            const fileName = files[i].name.toLowerCase();
            let matched = false;

            // Check if file name matches any category regex
            for (const [category, regex] of Object.entries(categoryKeywords)) {
                if (regex.test(fileName)) {
                    if (!matchedCategories[category]) {
                        matchedCategories[category] = [];
                    }
                    matchedCategories[category].push(files[i]);
                    matched = true;
                    break;
                }
            }

            if (!matched) {
                return `File "${files[i].name}" does not match any required categories.`;
            }
        }

        // Ensure the number of categories is between 1 and 4
        if (Object.keys(matchedCategories).length < 1 || Object.keys(matchedCategories).length > 4) {
            return 'You must upload between 1 and 4 valid files.';
        }

        return matchedCategories;
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!apiKey) {
            setErrorMessage('API key is required.');
            return;
        }

        const validationResult = validateFiles(selectedFiles);

        if (typeof validationResult === 'string') {
            setErrorMessage(validationResult);
            return;
        }

        const formData = new FormData();

        // Append files to formData
        Object.entries(validationResult).forEach(([category, files]) => {
            files.forEach(file => formData.append(`files[${category}]`, file));
        });

        axiosRequest("infinity/uploadEgnyteFiles", "POST", formData, {
            'Content-Type': 'multipart/form-data',
            'x-api-key': apiKey,  // Include the API key in the request headers
        }
        ).then((result) => {
            if (result.status === 200) {
                setSuccessMessage('Files uploaded successfully!');
                setErrorMessage('');
            } else {
                setErrorMessage(result?.data?.error ?? "")
            }
        }).catch((error) => {
            console.error('Error uploading files:', error);
            if (error.response && error.response.status === 401) {
                setErrorMessage('Invalid API key.');
            } else {
                setErrorMessage('Error uploading files. Please try again.');
            }
        });
    };

    // Handle sync
    const handleSync = async () => {
        if (!apiKey) {
            setErrorMessage('API key is required.');
            return;
        }

        try {
            const response = await axiosRequest('infinity/egnyte_sync', "POST", null, {
                'Content-Type': 'application/json',
                'x-api-key': apiKey,  // Include the API key in the request headers
            });

            if (response.status === 200) {
                setSuccessMessage('Sync completed successfully!');
                setErrorMessage('');
            }
        } catch (error) {
            console.error('Error syncing:', error);
            if (error.response && error.response.status === 401) {
                setErrorMessage('Invalid API key.');
            } else {
                setErrorMessage('Error syncing. Please try again.');
            }
        }
    };

    return (
        <div className="max-w-md mx-auto my-10 p-6 bg-white rounded-lg shadow-lg">
            <Typography variant="h5" className="text-xl font-semibold mb-4 text-center">
                Upload and Sync Excel Files
            </Typography>

            <TextField
                label="API Key"
                variant="outlined"
                fullWidth
                value={apiKey}
                onChange={handleApiKeyChange}
                className="mb-4"
            />

            <input
                type="file"
                accept=".xlsx, .xls"
                multiple
                onChange={handleFileChange}
                className="block w-full mb-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            {errorMessage && (
                <Typography color="error" className="mb-4 text-center">
                    {errorMessage}
                </Typography>
            )}

            {successMessage && (
                <Typography color="success" className="mb-4 text-center">
                    {successMessage}
                </Typography>
            )}

            <Box className="flex space-x-4 justify-center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    className="flex items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <UploadIcon className="mr-2" />
                    Upload
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSync}
                    className="flex items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    <SyncIcon className="mr-2" />
                    Sync
                </Button>
            </Box>
        </div>
    );
};

export default EgnyteSync;
