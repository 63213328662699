import axios from "axios"

const axiosRequest = (
  query,
  method = "GET",
  data = undefined,
  headers = undefined,
  useSandboxUrl = undefined
) => {
  // Request URL
  const url = `https://gulermak.mavryck.com/service/PMIntelCore/rest/${query}`
  const infinityUrl = `https://gulermak.mavryck.com/service/PMIntelCore/rest/${query}`
  const intelligentCoreUrl = `https://Sandbox-IntelligentCore-Balancer-1705632943.us-east-2.elb.amazonaws.com${query}`

  // Request Headers
  const reqHeaders = {
    "Access-Control-Allow-Origin": "*",
    crossDomain: true,
    withCredentials: useSandboxUrl ? false : true,
    method,
    url: useSandboxUrl ? intelligentCoreUrl : query.includes('infinity') ? infinityUrl : url,
  }

  // If POST
  if (method === "POST" && data) {
    reqHeaders.data = data
  }

  // If Custom Header Included
  if (headers) {
    reqHeaders.headers = {
      ...reqHeaders.headers,
      ...headers,
    }
  }

  return axios(reqHeaders)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        console.error('Server Error:', error.response.status);
        return error.response;
      } else if (error.request) {
        console.error('Network Error:', error.request);
        return error.request;
      } else {
        console.error('Error:', error.message);
        return error.message;
      }
    })
}

export default axiosRequest
